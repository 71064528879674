import { RemixBrowser, useLocation, useMatches } from "@remix-run/react"
import * as Sentry from "@sentry/remix"
import { StrictMode, startTransition, useEffect } from "react"
import { hydrateRoot } from "react-dom/client"

Sentry.init({
  dsn: "https://4f4164b51023f1881e0acafee94e32da@o4507846089179136.ingest.us.sentry.io/4507846159826944",
  tracesSampleRate: 1,

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches
    }),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true
    })
  ],

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1
})

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  )
})
